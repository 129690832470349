import React, { memo, useState, useEffect } from 'react'
import { getPostsReports } from 'app/api/posts'
import { validatePost } from 'app/api/posts'
import { Link } from 'react-router-dom'
import Spinner from 'app/components/Spinner'
import TableTh from 'app/components/Table/TableTh'
import Pagination from 'app/components/Pagination'
import UserPhoto from 'app/components/UserPhoto'
import { format, parseISO } from 'date-fns'

const PostsReports = () => {
  const [posts, setPosts] = useState([])
  const [loading, setLoading] = useState(false)
  const [skip, setSkip] = useState(0)
  const [total, setTotal] = useState(0)
  const limit = 30

  useEffect(() => {
    handleFetch(skip, limit)
  }, [skip, limit])

  const handleFetch = (skip, limit) => {
    setLoading(true)
    getPostsReports({ skip, limit }).then(response => {
      const { data } = response
      setPosts(data.posts)
      setTotal(data.total)
      setLoading(false)
    })
  }

  const handleValidateAction = id => {
    setLoading(true)
    validatePost(id).then(() => {
      handleFetch(skip, limit)
    })
  }

  return (
    <>
      <div className="card">
        {!Boolean(posts.length) && (
          <div className="p-3">Não há denúncias de publicações</div>
        )}
        {Boolean(posts.length) && (
          <table className="table card-table">
            <thead className="thead-light">
              <tr>
                <th width={1}></th>
                <th width={300}>
                  <TableTh title={'Autor'} />
                </th>
                <th>
                  <TableTh title={'Denúncias'} />
                </th>
                <th>
                  <TableTh title={'Curtidas'} />
                </th>
                <th>
                  <TableTh title={'Comentários'} />
                </th>
                <th>
                  <TableTh title={'Publicado em'} />
                </th>
                <th width={140}></th>
                <th width={140}></th>
              </tr>
            </thead>
            <tbody>
              {posts.map(post => {
                return (
                  <tr key={post.id}>
                    <td>
                      <UserPhoto user={post.owner} />
                    </td>
                    <td>{post.owner.name}</td>
                    <td>{post.reports}</td>
                    <td>{post.likes}</td>
                    <td>{post.comments}</td>
                    <td>
                      {format(parseISO(post.created_at), 'dd/MM/yyyy HH:mm')}
                    </td>
                    <td>
                      <Link to={`/posts/${post.id}`}>
                        <button className="btn btn-sm btn-default">
                          <span className="far fa-eye"></span> Visualizar
                        </button>
                      </Link>
                    </td>
                    <td>
                      <button
                        className="btn btn-sm btn-success mr-0"
                        onClick={() => handleValidateAction(post.id)}
                      >
                        <span className="fa fa-check"></span> Validar
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
      </div>

      <Pagination
        total={total}
        skip={skip}
        limit={limit}
        onClick={({ skip }) => setSkip(skip)}
      />

      {loading && <Spinner />}
    </>
  )
}

export default memo(PostsReports)
