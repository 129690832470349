import React, { memo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setListSort } from 'app/modules/posts'
import { list } from 'app/api/posts'
import { Link } from 'react-router-dom'
import Content from 'app/components/Content'
import Spinner from 'app/components/Spinner'
import TableTh from 'app/components/Table/TableTh'
import Pagination from 'app/components/Pagination'
import UserPhoto from 'app/components/UserPhoto'
import { format, parseISO } from 'date-fns'

const Posts = () => {
  const dispatch = useDispatch()
  const { sort, asc } = useSelector(state => state.posts)
  const [posts, setPosts] = useState([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [skip, setSkip] = useState(0)
  const limit = 50

  useEffect(() => {
    setLoading(true)
    list({ skip, limit, sort, asc }).then(response => {
      setTotal(response.data.total)
      setPosts(response.data.posts)
      setLoading(false)
    })
  }, [skip, limit, sort, asc])

  const handleSort = sort => {
    dispatch(setListSort({ sort, asc: !asc }))
  }

  return (
    <Content>
      <h4 className="font-weight-bold py-3 mb-1">
        <span className="text-muted font-weight-light">Lista /</span>{' '}
        Publicações
      </h4>

      {loading && <Spinner />}
      <div className="card">
        <table className="table card-table">
          <thead className="thead-light">
            <tr>
              <th width={1}></th>
              <th>
                <TableTh title={'Autor'} />
              </th>
              <th>
                <TableTh
                  title={'Curtidas'}
                  sort={sort === 'likes'}
                  asc={asc}
                  onClick={() => handleSort('likes')}
                />
              </th>
              <th>
                <TableTh
                  title={'Comentários'}
                  sort={sort === 'comments'}
                  asc={asc}
                  onClick={() => handleSort('comments')}
                />
              </th>
              <th>
                <TableTh
                  title={'Favoritos'}
                  sort={sort === 'bookmarks'}
                  asc={asc}
                  onClick={() => handleSort('bookmarks')}
                />
              </th>
              <th>
                <TableTh
                  title={'Visualizações'}
                  sort={sort === 'views'}
                  asc={asc}
                  onClick={() => handleSort('views')}
                />
              </th>
              <th>
                <TableTh
                  title={'Downloads'}
                  sort={sort === 'downloads'}
                  asc={asc}
                  onClick={() => handleSort('downloads')}
                />
              </th>
              <th>
                <TableTh
                  title={'Hashtags'}
                  sort={sort === 'hashtags'}
                  asc={asc}
                  onClick={() => handleSort('hashtags')}
                />
              </th>
              <th>
                <TableTh title={'Formato'} />
              </th>
              <th>
                <TableTh
                  title={'Publicação'}
                  sort={sort === 'created_at'}
                  asc={asc}
                  onClick={() => handleSort('created_at')}
                />
              </th>
              <th width={1}></th>
            </tr>
          </thead>
          <tbody>
            {posts.map(post => {
              return (
                <tr key={post.id}>
                  <td>
                    <UserPhoto user={post.owner} />
                  </td>
                  <td>{post.owner.name}</td>
                  <td>{post.likes}</td>
                  <td>{post.comments}</td>
                  <td>{post.bookmarks}</td>
                  <td>{post.views}</td>
                  <td>{post.downloads}</td>
                  <td>{post.hashtags}</td>
                  <td>
                    {post.attachments
                      ? 'Anexo'
                      : post.entries
                      ? 'Mídia'
                      : 'Texto'}
                  </td>
                  <td>
                    {format(parseISO(post.created_at), 'dd/MM/yyyy HH:mm')}
                  </td>
                  <td>
                    <Link to={`/posts/${post.id}`}>
                      <button className="btn icon-btn btn-sm btn-outline-primary">
                        <span className="far fa-eye"></span>
                      </button>
                    </Link>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <Pagination
        total={total}
        skip={skip}
        limit={limit}
        onClick={({ skip }) => setSkip(skip)}
      />
    </Content>
  )
}

export default memo(Posts)
