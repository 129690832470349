import api, { withConfig } from 'app/api'
import { stringify } from 'query-string'
import axios from 'axios'

export const getUsersIndicators = data => {
  return withConfig(config => {
    return axios
      .get(api`/v1/reports/indicators/users${'?' + stringify(data)}`, config)
      .then(({ data }) => data)
  })
}

export const getAdvertisementsIndicators = data => {
  return withConfig(config => {
    return axios
      .get(
        api`/v1/reports/indicators/advertisements${'?' + stringify(data)}`,
        config
      )
      .then(({ data }) => data)
  })
}

export const getPostsIndicators = data => {
  return withConfig(config => {
    return axios
      .get(api`/v1/reports/indicators/posts${'?' + stringify(data)}`, config)
      .then(({ data }) => data)
  })
}

export const getPostsIndicatorsByUsers = data => {
  return withConfig(config => {
    return axios
      .get(
        api`/v1/reports/indicators/posts_by_users${'?' + stringify(data)}`,
        config
      )
      .then(({ data }) => data)
  })
}

export const getActionsIndicators = data => {
  return withConfig(config => {
    return axios
      .get(api`/v1/reports/indicators/actions${'?' + stringify(data)}`, config)
      .then(({ data }) => data)
  })
}

export const getActionsIndicatorsByUsers = data => {
  return withConfig(config => {
    return axios
      .get(
        api`/v1/reports/indicators/actions_by_users${'?' + stringify(data)}`,
        config
      )
      .then(({ data }) => data)
  })
}

export const getFollowsIndicators = data => {
  return withConfig(config => {
    return axios
      .get(api`/v1/reports/indicators/follows${'?' + stringify(data)}`, config)
      .then(({ data }) => data)
  })
}

export const getFollowsIndicatorsByUsers = data => {
  return withConfig(config => {
    return axios
      .get(
        api`/v1/reports/indicators/follows_by_users${'?' + stringify(data)}`,
        config
      )
      .then(({ data }) => data)
  })
}
