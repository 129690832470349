import api, { withConfig } from 'app/api'
import { stringify } from 'query-string'
import axios from 'axios'

export const list = data =>
  withConfig(config =>
    axios
      .get(api`/v1/reports/users?${stringify(data)}`, config)
      .then(({ data }) => data)
  )
export const get = (id, data) =>
  withConfig(config =>
    axios
      .get(api`/v1/reports/users/${id}?${stringify(data)}`, config)
      .then(({ data }) => data)
  )
export const getUserComments = (id, data) =>
  withConfig(config =>
    axios
      .get(api`/v1/reports/users/${id}/comments?${stringify(data)}`, config)
      .then(({ data }) => data)
  )
