import React from 'react'
import styled from 'styled-components'

const StyledBackdrop = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 900;

  display: flex;
  align-items: center;
  justify-content: center;
`

const ActivityIndicator = styled.div`
  width: 40px;
  height: 40px;

  position: relative;
  margin: 100px auto;

  &::before,
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #70e1f5;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
  }

  &::after {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  @-webkit-keyframes sk-bounce {
    0%,
    100% {
      -webkit-transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
`

const Spinner = () => (
  <StyledBackdrop visible>
    <ActivityIndicator />
  </StyledBackdrop>
)

export default Spinner
