import React, { memo, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Spinner from 'app/components/Spinner'
import TableTh from 'app/components/Table/TableTh'
import Pagination from 'app/components/Pagination'
import Content from 'app/components/Content'
import UserPhoto from 'app/components/UserPhoto'
import { getComments } from 'app/api/reports'
import { format, parseISO } from 'date-fns'

const Comments = () => {
  const [comments, setComments] = useState([])
  const [loading, setLoading] = useState(false)
  const [skip, setSkip] = useState(0)
  const [total, setTotal] = useState(0)
  const limit = 30

  useEffect(() => {
    handleFetch(skip, limit)
  }, [skip, limit])

  const handleFetch = (skip, limit) => {
    setLoading(true)
    getComments({ skip, limit }).then(response => {
      const { data } = response
      setComments(data.comments)
      setTotal(data.total)
      setLoading(false)
    })
  }

  return (
    <Content>
      <h4 className="font-weight-bold py-3 mb-1">
        <span className="text-muted font-weight-light">Lista /</span>{' '}
        Comentários
      </h4>

      {loading && <Spinner />}

      <div className="card">
        {!Boolean(comments.length) && (
          <div className="p-3">Não há comentários para exibir</div>
        )}
        {Boolean(comments.length) && (
          <table className="table card-table">
            <thead className="thead-light">
              <tr>
                <th width={1}></th>
                <th width={200}>
                  <TableTh title={'Autor'} />
                </th>
                <th>
                  <TableTh title={'Comentário'} />
                </th>
                <th width={180}>
                  <TableTh title={'Data'} />
                </th>
                <th width={1}></th>
              </tr>
            </thead>
            <tbody>
              {comments.map(comment => {
                return (
                  <>
                    <tr key={comment.id}>
                      <td>
                        <UserPhoto user={comment.owner} />
                      </td>
                      <td>{comment.owner.name}</td>
                      <td>{comment.comment}</td>
                      <td>
                        {format(
                          parseISO(comment.created_at),
                          'dd/MM/yyyy HH:mm'
                        )}
                      </td>
                      <td>
                        <Link to={`/posts/${comment.post.id}`}>
                          <button className="btn icon-btn btn-sm btn-outline-primary">
                            <span className="far fa-eye"></span>
                          </button>
                        </Link>
                      </td>
                    </tr>
                    {comment.answers.map(answer => {
                      return (
                        <tr key={answer.id} style={{ background: '#f5f5f5' }}>
                          <td>
                            <UserPhoto user={answer.owner} />
                          </td>
                          <td>{answer.owner.name}</td>
                          <td>
                            <i className="fa fa-share"></i>
                            {' ' + answer.comment}
                          </td>
                          <td>
                            {format(
                              parseISO(answer.created_at),
                              'dd/MM/yyyy HH:mm'
                            )}
                          </td>
                          <td>
                            <Link to={`/posts/${answer.post.id}`}>
                              <button className="btn icon-btn btn-sm btn-outline-primary">
                                <span className="far fa-eye"></span>
                              </button>
                            </Link>
                          </td>
                        </tr>
                      )
                    })}
                  </>
                )
              })}
            </tbody>
          </table>
        )}
      </div>

      <Pagination
        total={total}
        skip={skip}
        limit={limit}
        onClick={({ skip }) => setSkip(skip)}
      />
    </Content>
  )
}

export default memo(Comments)
