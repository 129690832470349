import Immutable, { merge } from 'seamless-immutable'
import { startOfMonth, endOfMonth } from 'date-fns'

export const FILTER_UPDATE = 'activities/FILTER_UPDATE'

const initialState = Immutable({
  startDate: startOfMonth(new Date()).toISOString(),
  endDate: endOfMonth(new Date()).toISOString()
})

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTER_UPDATE:
      return merge(state, {
        startDate: action.startDate,
        endDate: action.endDate
      })

    default:
      return state
  }
}

export const setListFilter = ({ startDate, endDate }) => async dispatch => {
  dispatch({ type: FILTER_UPDATE, startDate, endDate })
}

export const clearListFilter = () => async dispatch => {
  dispatch({
    type: FILTER_UPDATE,
    startDate: startOfMonth(new Date()).toISOString(),
    endDate: endOfMonth(new Date()).toISOString()
  })
}
