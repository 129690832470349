import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import rootReducer from './modules'

const enhancers = []
const initialState = {}
const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: [''],
  stateReconciler: autoMergeLevel2
}
const middleware = [thunk]

/*eslint-disable */
const composeSetup =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose
/*eslint-enable */

const composedEnhancers = composeSetup(
  applyMiddleware(...middleware),
  ...enhancers
)

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(
  persistedReducer,
  initialState,
  composedEnhancers
)
export const persistor = persistStore(store)
