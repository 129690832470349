import api, { withConfig } from 'app/api'
import { stringify } from 'query-string'
import axios from 'axios'

export const getTimelineUsers = data =>
  withConfig(config =>
    axios
      .get(api`/v1/reports/timeline/users${'?' + stringify(data)}`, config)
      .then(({ data }) => data)
  )
export const getTimelinePosts = data =>
  withConfig(config =>
    axios
      .get(api`/v1/reports/timeline/posts${'?' + stringify(data)}`, config)
      .then(({ data }) => data)
  )
export const getTimelineLikes = data =>
  withConfig(config =>
    axios
      .get(api`/v1/reports/timeline/likes${'?' + stringify(data)}`, config)
      .then(({ data }) => data)
  )
export const getTimelineComments = data =>
  withConfig(config =>
    axios
      .get(api`/v1/reports/timeline/comments${'?' + stringify(data)}`, config)
      .then(({ data }) => data)
  )

export const getUserPoints = data =>
  withConfig(config =>
    axios
      .get(api`/v1/reports/users/points${'?' + stringify(data)}`, config)
      .then(({ data }) => data)
  )
export const getUsersByState = data =>
  withConfig(config =>
    axios
      .get(api`/v1/reports/users/states${'?' + stringify(data)}`, config)
      .then(({ data }) => data)
  )
export const getUsersByCity = data =>
  withConfig(config =>
    axios
      .get(api`/v1/reports/users/cities${'?' + stringify(data)}`, config)
      .then(({ data }) => data)
  )

export const getComments = data =>
  withConfig(config =>
    axios
      .get(api`/v1/reports/users/comments?${stringify(data)}`, config)
      .then(({ data }) => data)
  )
