import React, { memo, useState, useEffect } from 'react'
import { getAdvertisementsReports } from 'app/api/advertisements'
import { validateAdvertisement } from 'app/api/advertisements'
import { Link } from 'react-router-dom'
import Spinner from 'app/components/Spinner'
import TableTh from 'app/components/Table/TableTh'
import Pagination from 'app/components/Pagination'
import UserPhoto from 'app/components/UserPhoto'
import { format, parseISO } from 'date-fns'

const AdvertisementsReports = () => {
  const [advertisements, setAdvertisements] = useState([])
  const [loading, setLoading] = useState(false)
  const [skip, setSkip] = useState(0)
  const [total, setTotal] = useState(0)
  const limit = 30

  useEffect(() => {
    handleFetch(skip, limit)
  }, [skip, limit])

  const handleFetch = (skip, limit) => {
    setLoading(true)
    getAdvertisementsReports({ skip, limit }).then(response => {
      const { data } = response
      setAdvertisements(data.advertisements)
      setTotal(data.total)
      setLoading(false)
    })
  }

  const handleValidateAction = id => {
    setLoading(true)
    validateAdvertisement(id).then(() => {
      handleFetch(skip, limit)
    })
  }

  return (
    <>
      <div className="card">
        {!Boolean(advertisements.length) && (
          <div className="p-3">Não há denúncias de clasificados</div>
        )}
        {Boolean(advertisements.length) && (
          <table className="table card-table">
            <thead className="thead-light">
              <tr>
                <th width={1}></th>
                <th width={300}>
                  <TableTh title={'Autor'} />
                </th>
                <th>
                  <TableTh title={'Denúncias'} />
                </th>
                <th>
                  <TableTh title={'Recomendações'} />
                </th>
                <th>
                  <TableTh title={'Comentários'} />
                </th>
                <th>
                  <TableTh title={'Publicado em'} />
                </th>
                <th width={140}></th>
                <th width={140}></th>
              </tr>
            </thead>
            <tbody>
              {advertisements.map(advertisement => {
                return (
                  <tr key={advertisement.id}>
                    <td>
                      <UserPhoto user={advertisement.owner} />
                    </td>
                    <td>{advertisement.owner.name}</td>
                    <td>{advertisement.reports}</td>
                    <td>{advertisement.recommendations}</td>
                    <td>{advertisement.comments}</td>
                    <td>
                      {format(
                        parseISO(advertisement.created_at),
                        'dd/MM/yyyy HH:mm'
                      )}
                    </td>
                    <td>
                      <Link to={`/advertisements/${advertisement.id}`}>
                        <button className="btn btn-sm btn-default">
                          <span className="far fa-eye"></span> Visualizar
                        </button>
                      </Link>
                    </td>
                    <td>
                      <button
                        className="btn btn-sm btn-success"
                        onClick={() => handleValidateAction(advertisement.id)}
                      >
                        <span className="fa fa-check"></span> Validar
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
      </div>

      <Pagination
        total={total}
        skip={skip}
        limit={limit}
        onClick={({ skip }) => setSkip(skip)}
      />

      {loading && <Spinner />}
    </>
  )
}

export default memo(AdvertisementsReports)
