import api, { withConfig } from 'app/api'
import { stringify } from 'query-string'
import axios from 'axios'

export const getAdvertisement = id =>
  withConfig(config =>
    axios.get(api`/v1/advertisements/${id}`, config).then(({ data }) => data)
  )
export const getAdvertisementsReports = data =>
  withConfig(config =>
    axios
      .get(api`/v1/advertisements/reports${'?' + stringify(data)}`, config)
      .then(({ data }) => data)
  )
export const validateAdvertisement = id =>
  withConfig(config =>
    axios
      .put(api`/v1/advertisements/${id}/validate`, null, config)
      .then(({ data }) => data)
  )
