import { combineReducers } from 'redux'
import app from './app'
import users from './users'
import posts from './posts'
import tags from './tags'
import hashtags from './hashtags'
import activities from './activities'

export default combineReducers({
  app,
  users,
  posts,
  tags,
  hashtags,
  activities
})
