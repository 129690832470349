import React, { memo, useEffect, useState } from 'react'
import LineChart from 'app/components/Charts/LineChart'
import Spinner from 'app/components/Spinner'
import { getTimelinePosts } from 'app/api/reports'
import { format, parseISO } from 'date-fns'

const options = [
  {
    border: '#0f4c75',
    background: 'rgba(15, 76, 117, 0.8)',
    label: 'Novas publicações',
    variable: 'posts'
  }
]

const TimelinePosts = ({ filter }) => {
  const [labels, setLabels] = useState([])
  const [datasets, setDatasets] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (Boolean(filter.startDate) && Boolean(filter.endDate)) {
      setLoading(true)
      getTimelinePosts(filter).then(response => {
        if (!response.data || !response.data.length) {
          return false
        }

        setLabels(
          response.data.map(dataset => {
            return format(parseISO(dataset.date), 'dd/MM/yyyy')
          })
        )

        setDatasets(
          options.map(option => ({
            label: option.label,
            borderWidth: 1,
            borderColor: option.border,
            backgroundColor: option.background,
            data: response.data.map(data => data[option.variable])
          }))
        )

        setLoading(false)
      })
    }
  }, [filter])

  if (loading) {
    return <Spinner />
  }

  return (
    <LineChart
      id={'chart-bar-timeline-posts'}
      label={''}
      labels={labels}
      datasets={datasets}
    />
  )
}

export default memo(TimelinePosts)
