import Immutable, { merge } from 'seamless-immutable'

export const SORT_UPDATE = 'hashtags/SORT_UPDATE'

const initialState = Immutable({
  sort: 'posts',
  asc: false
})

export default (state = initialState, action) => {
  switch (action.type) {
    case SORT_UPDATE:
      return merge(state, {
        sort: action.sort,
        asc: action.asc
      })

    default:
      return state
  }
}

export const setListSort = ({ sort, asc }) => async dispatch => {
  dispatch({ type: SORT_UPDATE, sort, asc })
}
