/* eslint-disable */
import React, { memo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setListFilter } from 'app/modules/activities'
import { get as getActivities } from 'app/api/activities'
import { getUserPoints, getUsersByState } from 'app/api/reports'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import { format, parseISO } from 'date-fns'
import Content from 'app/components/Content'
import Spinner from 'app/components/Spinner'
import TableTh from 'app/components/Table/TableTh'
import TimelineUsers from 'app/components/Dashboard/TimelineUsers'
import TimelinePosts from 'app/components/Dashboard/TimelinePosts'
import TimelineLikes from 'app/components/Dashboard/TimelineLikes'
import TimelineComments from 'app/components/Dashboard/TimelineComments'
import IndicatorsUsers from 'app/components/Dashboard/IndicatorsUsers'
import IndicatorsAdvertisements from 'app/components/Dashboard/IndicatorsAdvertisements'
import IndicatorsPosts from 'app/components/Dashboard/IndicatorsPosts'
import IndicatorsPostsByAccess from 'app/components/Dashboard/IndicatorsPostsByAccess'
import IndicatorsActions from 'app/components/Dashboard/IndicatorsActions'
import IndicatorsActionsByAccess from 'app/components/Dashboard/IndicatorsActionsByAccess'
import IndicatorsFollows from 'app/components/Dashboard/IndicatorsFollows'
import IndicatorsFollowsByAccess from 'app/components/Dashboard/IndicatorsFollowsByAccess'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import markerState from 'app/assets/img/markerState.png'
import markerPoint from 'app/assets/img/markerPoint.png'
import coords from 'app/helpers/states'

const Dashboard = () => {
  const dispatch = useDispatch()
  const { startDate, endDate } = useSelector(state => state.activities)
  const [ filter, setFilter ] = useState({})
  const [tabindex, setTabindex] = useState(0)
  const [activities, setActivities] = useState({})
  const [loading, setLoading] = useState(false)
  const [markers, setMarkers] = useState([])

  useEffect(() => {
    setFilter({
      startDate: format(parseISO(startDate), 'yyyy-MM-dd'),
      endDate: format(parseISO(endDate), 'yyyy-MM-dd')
    })
  }, [startDate, endDate])

  useEffect(() => {
    setLoading(true)

    switch (tabindex) {
      case 0:
        processActivities(filter).then(() => {
          setLoading(false)
        })
        break
      case 2:
        processMap(filter).then(() => {
          setLoading(false)
        })
        break
      case 3:
        processPointsMap(filter).then(() => {
          setLoading(false)
        })
        break
    }

    setLoading(false)

  }, [filter, tabindex])

  // Process activities data
  const processActivities = async filter => {
    const response = await getActivities(filter)
    setActivities(response.data.activities)
  }

  // Process state map
  const processMap = async filter => {
    const map = new window.google.maps.Map(
      document.getElementById('statemap'),
      { zoom: 5, center: { lat: -14.5314, lng: -54.2412 } }
    )
    const states = []
    const response = await getUsersByState(filter)
    if (response.data.states) {
      coords.map(state => {
        if (response.data.states.all[state.initials]) {
          var totals = response.data.states.all[state.initials]
          states.push({ ...state, ...totals })
        }
      })
    }
    markers.map(marker => marker.setMap(null))
    states.map(state => {
      if (state.users) {
        var mapmarker = new window.google.maps.Marker({
          map,
          label: state.users.toString(),
          icon: markerState,
          position: { lat: state.coords[0], lng: state.coords[1] }
        })
        setMarkers([...markers, mapmarker])
      }
    })
  }

  // Process points map
  const processPointsMap = async filter => {
    const map = new window.google.maps.Map(
      document.getElementById('pointsmap'),
      { zoom: 5, center: { lat: -14.5314, lng: -54.2412 } }
    )
    const response = await getUserPoints(filter)
    const points = []
    if (response.data.points) {
      response.data.points.map(point => {
        if(Boolean(point.location) && (point.location.coordinates)){
          var marker = new window.google.maps.Marker({
            map,
            icon: {
              url: markerPoint,
              size: new window.google.maps.Size(20, 20),
              anchor: new window.google.maps.Point(15, 15)
            },
            position: {
              lat: point.location.coordinates[1],
              lng: point.location.coordinates[0]
            }
          })
          points.push(marker)
        }
      })
    }
    //new window.MarkerClusterer(map, points, {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'})
  }

  const setStartDate = date => {
    dispatch(setListFilter({ startDate: date.toISOString(), endDate }))
  }

  const setEndDate = date => {
    dispatch(setListFilter({ startDate, endDate: date.toISOString() }))
  }

  return (
    <Content>
      <div className="row">
        <div className="pull-left col-md-8">
          <h4 className="font-weight-bold py-4">
            <span className="text-muted font-weight-light">Dados /</span>{' '}
            Atividades
          </h4>
        </div>
        <div className="pull-right col-md-4">
          <div className="form-row py-3">
            <div className="form-group col-md-6">
              <DatePicker
                className={'form-control text-center'}
                selected={parseISO(startDate)}
                dateFormat={'dd/MM/yyyy'}
                popperPlacement={'top-start'}
                onChange={date => setStartDate(date)}
                startDate={parseISO(startDate)}
                endDate={parseISO(endDate)}
                selectsStart
              />
            </div>
            <div className="form-group col-md-6">
              <DatePicker
                className={'form-control text-center'}
                selected={parseISO(endDate)}
                dateFormat={'dd/MM/yyyy'}
                popperPlacement={'top-end'}
                onChange={date => setEndDate(date)}
                startDate={parseISO(startDate)}
                endDate={parseISO(endDate)}
                minDate={parseISO(startDate)}
                selectsEnd
              />
            </div>
          </div>
        </div>
      </div>

      <Tabs defaultIndex={tabindex} onSelect={index => setTabindex(index)}>

        <TabsMenu>
          <TabsMenuOption active={tabindex === 0}>
            Informações gerais
          </TabsMenuOption>
          <TabsMenuOption active={tabindex === 1}>
            Informações gráficas
          </TabsMenuOption>
          <TabsMenuOption active={tabindex === 2}>
            Mapa por estados
          </TabsMenuOption>
          <TabsMenuOption active={tabindex === 3}>
            Mapa por usuários
          </TabsMenuOption>
          <TabsMenuOption active={tabindex === 4}>
            Indicadores
          </TabsMenuOption>
        </TabsMenu>

        <TabPanel>
          <div className={'card'}>
            <table className="table card-table">
              <thead className="thead-light">
                <tr>
                  <th>
                    <TableTh title={'Informação'} />
                  </th>
                  <th width={200}>
                    <TableTh title={'Filtro'} />
                  </th>
                  <th width={200}>
                    <TableTh title={'Total'} />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{`Cadastros de usuários`}</td>
                  <td>
                    {activities.range ? Number(activities.range.users) : '-'}
                  </td>
                  <td>{activities.all ? Number(activities.all.users) : '-'}</td>
                </tr>
                <tr>
                  <td>{`Novas publicações`}</td>
                  <td>
                    {activities.range ? Number(activities.range.posts) : '-'}
                  </td>
                  <td>{activities.all ? Number(activities.all.posts) : '-'}</td>
                </tr>
                <tr>
                  <td>{`Novas publicações com mídia`}</td>
                  <td>
                    {activities.range
                      ? Number(activities.range.postsWithMedia)
                      : '-'}
                  </td>
                  <td>
                    {activities.all
                      ? Number(activities.all.postsWithMedia)
                      : '-'}
                  </td>
                </tr>
                <tr>
                  <td>{`Novas publicações com anexo`}</td>
                  <td>
                    {activities.range
                      ? Number(activities.range.postsWithAttachments)
                      : '-'}
                  </td>
                  <td>
                    {activities.all
                      ? Number(activities.all.postsWithAttachments)
                      : '-'}
                  </td>
                </tr>
                <tr>
                  <td>{`Curtidas em publicações`}</td>
                  <td>
                    {activities.range ? Number(activities.range.likes) : '-'}
                  </td>
                  <td>{activities.all ? Number(activities.all.likes) : '-'}</td>
                </tr>
                <tr>
                  <td>{`Comentários em publicações`}</td>
                  <td>
                    {activities.range ? Number(activities.range.comments) : '-'}
                  </td>
                  <td>
                    {activities.all ? Number(activities.all.comments) : '-'}
                  </td>
                </tr>
                <tr>
                  <td>{`Publicações salvas`}</td>
                  <td>
                    {activities.range
                      ? Number(activities.range.bookmarks)
                      : '-'}
                  </td>
                  <td>
                    {activities.all ? Number(activities.all.bookmarks) : '-'}
                  </td>
                </tr>
                <tr>
                  <td>{`Novos seguidores`}</td>
                  <td>
                    {activities.range ? Number(activities.range.follows) : '-'}
                  </td>
                  <td>
                    {activities.all ? Number(activities.all.follows) : '-'}
                  </td>
                </tr>
                <tr>
                  <td>{`Novas inscrições`}</td>
                  <td>
                    {activities.range
                      ? Number(activities.range.subscriptions)
                      : '-'}
                  </td>
                  <td>
                    {activities.all
                      ? Number(activities.all.subscriptions)
                      : '-'}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </TabPanel>

        <TabPanel>
          <div className={'card'}>
            <ChartWrapper>
              <h4 className="py-3 text-center mb-0 mt-4 ml-4">Novos usuários</h4>
              <TimelineUsers filter={filter}/>
            </ChartWrapper>
            <ChartWrapper>
              <h4 className="py-3 text-center mb-0 mt-4 ml-4">Novas publicações</h4>
              <TimelinePosts filter={filter}/>
            </ChartWrapper>
            <ChartWrapper>
              <h4 className="py-3 text-center mb-0 mt-4 ml-4">Curtidas em publicações</h4>
              <TimelineLikes filter={filter}/>
            </ChartWrapper>
            <ChartWrapper>
              <h4 className="py-3 text-center mb-0 mt-4 ml-4">Comentários em publicações</h4>
              <TimelineComments filter={filter}/>
            </ChartWrapper>
          </div>
        </TabPanel>

        <TabPanel>
          <div className={'card'}>
            <div className={'card'} style={{ overflow: 'hidden' }}>
              <div
                id={'statemap'}
                style={{ width: '100%', height: '140vh' }}
              ></div>
            </div>
          </div>
        </TabPanel>

        <TabPanel>
          <div className={'card'}>
            <div className={'card'} style={{ overflow: 'hidden' }}>
              <div
                id={'pointsmap'}
                style={{ width: '100%', height: '140vh' }}
              ></div>
            </div>
          </div>
        </TabPanel>

        <TabPanel>
          <div className={'card'}>
            <ChartWrapper>
              <h4 className="py-3 text-center mb-0 mt-4 ml-4">Indicador de novos usuários</h4>
              <IndicatorsUsers filter={filter}/>
            </ChartWrapper>

            <ChartWrapper>
              <h4 className="py-3 text-center mb-0 mt-4 ml-4">Indicador de novos anúncios</h4>
              <IndicatorsAdvertisements filter={filter}/>
            </ChartWrapper>

            <ChartWrapper>
              <h4 className="py-3 text-center mb-0 mt-4 ml-4">Indicador de novas publicações</h4>
              <IndicatorsPosts filter={filter}/>
            </ChartWrapper>

            <ChartWrapper>
              <h4 className="py-3 text-center mb-0 mt-4 ml-4">Indicador de novas publicações por acessos de usuários</h4>
              <IndicatorsPostsByAccess filter={filter}/>
            </ChartWrapper>

            <ChartWrapper>
              <h4 className="py-3 text-center mb-0 mt-4 ml-4">Indicador de interações em publicações</h4>
              <IndicatorsActions filter={filter}/>
            </ChartWrapper>

            <ChartWrapper>
              <h4 className="py-3 text-center mb-0 mt-4 ml-4">Indicador de interações em publicações por acessos de usuários</h4>
              <IndicatorsActionsByAccess filter={filter}/>
            </ChartWrapper>

            <ChartWrapper>
              <h4 className="py-3 text-center mb-0 mt-4 ml-4">Indicador de ações de seguir</h4>
              <IndicatorsFollows filter={filter}/>
            </ChartWrapper>

            <ChartWrapper>
              <h4 className="py-3 text-center mb-0 mt-4 ml-4">Indicador de ações de seguir por acessos de usuários</h4>
              <IndicatorsFollowsByAccess filter={filter}/>
            </ChartWrapper>
          </div>
        </TabPanel>

      </Tabs>

      {loading && <Spinner />}
    </Content>
  )
}

const TabsMenu = styled(TabList)`
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  padding: 10px;
  margin-bottom: 20px;
`
const TabsMenuOption = styled(Tab)`
  text-align: center;
  padding: 5px 15px;
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  border-bottom-style: solid;
  border-color: #4e5155;
  border-bottom-width: ${props => (props.active ? '1px' : '0')};
  cursor: pointer;
`
const ChartWrapper = styled.div`
  margin: 0 15px 15px 15px;
`

export default memo(Dashboard)
