import React, { memo, useState, useEffect } from 'react'
import ReactPlayer from 'react-player'
import { getAdvertisement } from 'app/api/advertisements'
import Content from 'app/components/Content'
import Spinner from 'app/components/Spinner'
import UserPhoto from 'app/components/UserPhoto'
import { format, parseISO } from 'date-fns'

const Advertisement = ({ match }) => {
  const [advertisement, setAdvertisement] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    getAdvertisement(match.params.id).then(response => {
      setAdvertisement(response.data)
      setLoading(false)
    })
  }, [match.params.id])

  if (loading) {
    return (
      <Content>
        <Spinner />
      </Content>
    )
  }

  return (
    <Content>
      <div className="row">
        <div className="pull-left col-md-8">
          <h4 className="font-weight-bold py-3 mb-1">
            <span className="text-muted font-weight-light">Detalhes / </span>{' '}
            Publicação de {advertisement.owner.name}
          </h4>
        </div>
      </div>

      <div className="row">
        <div className="col-md-5">
          <div className="card">
            <h6 className="card-header">Classificado</h6>
            {Boolean(advertisement.entries) &&
              advertisement.entries.map(entry => {
                if (entry.type === 'picture') {
                  return (
                    <div className="img-thumbnail">
                      <img
                        src={entry.url}
                        alt={entry.name}
                        className="img-fluid"
                        style={{ minWidth: '100%' }}
                      />
                    </div>
                  )
                } else {
                  return (
                    <ReactPlayer
                      url={entry.url}
                      width={'100%'}
                      controls={true}
                    />
                  )
                }
              })}
            {Boolean(advertisement.description) && (
              <div className="card-body">
                <div>
                  <p style={{ whiteSpace: 'pre-wrap', marginBottom: 0 }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: advertisement.description
                      }}
                    />
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="col-md-7">
          <div className="card">
            <h6 className="card-header">Informações</h6>
            <table className="table card-table">
              <tbody>
                <tr>
                  <td>Autor</td>
                  <td>
                    <div className="media align-items-center">
                      <UserPhoto user={advertisement.owner} />
                      <div className="media-body ml-3">
                        <div className="d-block text-body">
                          <strong>{advertisement.owner.name}</strong>
                          <br />
                          <span className="text-muted">
                            {advertisement.owner.email}
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Recomendações</td>
                  <td>{Number(advertisement.recommendations)}</td>
                </tr>
                <tr>
                  <td>Comentários</td>
                  <td>{Number(advertisement.comments)}</td>
                </tr>
                <tr>
                  <td>Denúncias</td>
                  <td>{Number(advertisement.reports)}</td>
                </tr>
                <tr>
                  <td>Favoritos</td>
                  <td>{Number(advertisement.bookmarks)}</td>
                </tr>
                <tr>
                  <td>Impressões</td>
                  <td>{Number(advertisement.impressions)}</td>
                </tr>
                <tr>
                  <td>Visualizações</td>
                  <td>{Number(advertisement.views)}</td>
                </tr>
                <tr>
                  <td>Publicação</td>
                  <td>
                    {format(
                      parseISO(advertisement.created_at),
                      'dd/MM/yyyy HH:mm'
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Atualização</td>
                  <td>
                    {format(
                      parseISO(advertisement.updated_at),
                      'dd/MM/yyyy HH:mm'
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Content>
  )
}

export default memo(Advertisement)
