import React, { memo } from 'react'
import styled from 'styled-components'

const Pagination = ({ skip, limit, total, onClick }) => {
  const pages = Math.ceil(total / limit)
  const current = Math.floor((skip + limit) / limit)

  if (total < limit) {
    return
  }

  const getPrev = () => {
    return (
      <li className={`page-item ${current <= 1 ? 'disabled' : ''}`}>
        <div
          className="page-link"
          onClick={() => onClick({ skip: limit * (current - 2) })}
        >
          «
        </div>
      </li>
    )
  }

  const getPages = () => {
    const buttons = []
    new Array(7).fill().forEach((e, i) => {
      var p = (current - 3 >= 1 ? current - 3 : 1) + i
      if (p <= pages) {
        buttons.push(
          <li
            key={p}
            className={`page-item ${p === current ? 'active' : ''}`}
            onClick={() => onClick({ skip: limit * (p - 1) })}
          >
            <div className="page-link">{p}</div>
          </li>
        )
      }
    })
    return buttons
  }

  const getNext = () => {
    return (
      <li className={`page-item ${current >= pages ? 'disabled' : ''}`}>
        <div
          className="page-link"
          onClick={() => onClick({ skip: limit * current })}
        >
          »
        </div>
      </li>
    )
  }

  return (
    <PaginationWrapper className="mt-4">
      <div>
        <p className="mb-0">
          Mostrando <b>{skip + 1}</b> à <b>{skip + limit}</b> de um total de{' '}
          <b>{total}</b> itens
        </p>
      </div>
      <ul className="pagination">
        {getPrev()}
        {getPages()}
        {getNext()}
      </ul>
    </PaginationWrapper>
  )
}

const PaginationWrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export default memo(Pagination)
