import Immutable, { merge } from 'seamless-immutable'
import { signIn } from 'app/api/user'
import { clearListFilter as clearListFilterAcitivities } from 'app/modules/activities'
import { clearListFilter as clearListFilterUsers } from 'app/modules/users'

export const USER_UPDATE = 'app/USER_UPDATE'
export const TOKEN_UPDATE = 'app/TOKEN_UPDATE'
export const TOGGLE_SIDEBAR = 'app/TOGGLE_SIDEBAR'

const initialState = Immutable({
  user: false,
  access_token: '',
  sidebar: true
})

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_UPDATE:
      return merge(state, {
        user: action.user
      })

    case TOKEN_UPDATE:
      return merge(state, {
        access_token: action.access_token
      })

    case TOGGLE_SIDEBAR:
      return merge(state, { sidebar: !state.sidebar })

    default:
      return state
  }
}

export const signInUser = values => async dispatch =>
  signIn(values).then(({ data }) => {
    dispatch({
      type: USER_UPDATE,
      user: values
    })
    dispatch({
      type: TOKEN_UPDATE,
      access_token: data.token
    })
    dispatch(clearListFilterAcitivities())
    dispatch(clearListFilterUsers())
  })

export const signOutUser = () => async dispatch => {
  dispatch({
    type: USER_UPDATE,
    user: false
  })
  dispatch({
    type: TOKEN_UPDATE,
    access_token: undefined
  })
}

export const toggleSidebar = () => async dispatch => {
  dispatch({ type: TOGGLE_SIDEBAR })
}
