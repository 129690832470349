import React, { memo, useState, useEffect } from 'react'
import { getSettings, updateSetting } from 'app/api/settings'
import Content from 'app/components/Content'
import Spinner from 'app/components/Spinner'
import TableTh from 'app/components/Table/TableTh'
import { format, parseISO } from 'date-fns'

const Settings = () => {
  const [settings, setSettings] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getSettings().then(response => {
      setSettings(response.data)
      setLoading(false)
    })
  }, [])

  const handleToogleValue = async (name, value) => {
    setLoading(true)
    await updateSetting({
      name,
      value: value === 'disabled' ? 'enabled' : 'disabled'
    })
    getSettings().then(response => {
      setSettings(response.data)
      setLoading(false)
    })
  }

  return (
    <Content>
      <h4 className="font-weight-bold py-3 mb-1">Configurações</h4>

      {loading && <Spinner />}
      <div className="card">
        <table className="table card-table">
          <thead className="thead-light">
            <tr>
              <th>
                <TableTh title={'Configuração'} />
              </th>
              <th>
                <TableTh title={'Valor'} />
              </th>
              <th>
                <TableTh title={'Última alteração'} />
              </th>
            </tr>
          </thead>
          <tbody>
            {settings.map(setting => {
              return (
                <tr key={setting.id}>
                  <td>{setting.description}</td>
                  <td>
                    <button
                      className={'btn btn-sm btn-default'}
                      onClick={() =>
                        handleToogleValue(setting.name, setting.value)
                      }
                    >
                      {setting.value === 'disabled'
                        ? 'Habilitar'
                        : 'Desabilitar'}
                    </button>
                  </td>
                  <td>
                    {format(parseISO(setting.updated_at), 'dd/MM/yyyy HH:mm')}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </Content>
  )
}

export default memo(Settings)
