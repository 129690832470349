/* eslint-disable */
import React, { memo, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { signOutUser, toggleSidebar } from 'app/modules/app'

import styled from 'styled-components'

const Content = ({ children }) => {
  const dispatch = useDispatch()
  const sidebar = useSelector(state => state.app.sidebar)

  useEffect(() => {
    if (sidebar) {
      document.body.classList.remove('layout-collapsed')
    } else {
      document.body.classList.add('layout-collapsed')
    }
  }, [sidebar])

  return (
    <div className="layout-wrapper layout-2">
      <div className="layout-inner">
        {/* SIDENAV */}
        <div
          id="layout-sidenav"
          className="layout-sidenav sidenav sidenav-vertical bg-sidenav-theme"
        >
          <Logo>
            <LogoImage>
              <img
                src={require('app/assets/img/logo.png')}
                width={30}
                alt={'Praticantes'}
              />
            </LogoImage>
            <LogoText>Praticantes</LogoText>
          </Logo>

          <div className="sidenav-divider mt-0"></div>

          {/* MENU */}
          <ul className="sidenav-inner py-1">
            <li className="sidenav-item">
              <NavLink to="/dashboard" className="sidenav-link" activeClassName="active" >
                <i className="sidenav-icon fa fa-chart-line"></i>
                <div>Dashboard</div>
              </NavLink>
            </li>

            <li className="sidenav-item">
              <NavLink to="/analytics" className="sidenav-link" activeClassName="active" >
                <i className="sidenav-icon fa fa-chart-bar"></i>
                <div>Analytics</div>
              </NavLink>
            </li>

            <li className="sidenav-item">
              <NavLink
                to="/users"
                className="sidenav-link"
                activeClassName="active"
              >
                <i className="sidenav-icon fa fa-users"></i>
                <div>Usuários</div>
              </NavLink>
            </li>

            <li className="sidenav-item">
              <NavLink
                to="/posts"
                className="sidenav-link"
                activeClassName="active"
              >
                <i className="sidenav-icon fa fa-list-alt"></i>
                <div>Publicações</div>
              </NavLink>
            </li>

            <li className="sidenav-item">
              <NavLink
                to="/comments"
                className="sidenav-link"
                activeClassName="active"
              >
                <i className="sidenav-icon fa fa-comment"></i>
                <div>Comentários</div>
              </NavLink>
            </li>

            <li className="sidenav-item">
              <NavLink to="/reports" className="sidenav-link" activeClassName="active">
                <i className="sidenav-icon fa fa-exclamation-triangle"></i>
                <div>Denúncias</div>
              </NavLink>
            </li>

            <li className="sidenav-item">
              <NavLink to="/tags" className="sidenav-link" activeClassName="active">
                <i className="sidenav-icon fa fa-tag"></i>
                <div>Tags</div>
              </NavLink>
            </li>

            <li className="sidenav-item">
              <NavLink
                to="/hashtags"
                className="sidenav-link"
                activeClassName="active"
              >
                <i className="sidenav-icon fa fa-hashtag"></i>
                <div>Hashtags</div>
              </NavLink>
            </li>

            <li className="sidenav-item">
              <NavLink
                to="/settings"
                className="sidenav-link"
                activeClassName="active"
              >
                <i className="sidenav-icon fa fa-cogs"></i>
                <div>Configurações</div>
              </NavLink>
            </li>
          </ul>
        </div>

        {/* CONTAINER */}
        <div className="layout-container">
          <nav
            className="layout-navbar navbar navbar-expand-lg align-items-lg-center bg-navbar-theme container-p-x"
            id="layout-navbar"
          >
            <div className="layout-sidenav-toggle navbar-nav align-items-lg-center mr-auto">
              <a
                className="nav-item nav-link px-0 mr-lg-4"
                onClick={() => dispatch(toggleSidebar())}
              >
                <i className="fa fa-bars text-large align-middle"></i>
              </a>
            </div>

            <button
              type="button"
              className="btn btn-sm btn-danger"
              onClick={() => dispatch(signOutUser())}
            >
              Sair
            </button>
          </nav>

          <div className="layout-content">
            <div className="container-fluid flex-grow-1 container-p-y">
              {children}
            </div>
          </div>
        </div>
      </div>

      <div className="layout-overlay layout-sidenav-toggle"></div>
    </div>
  )
}

const Logo = styled.div`
  display: flex;
  height: 66px;
  overflow: hidden;
  padding: 0.75rem 2rem;
  justify-content: space-between;
  align-items: center;
`
const LogoImage = styled.div`
  max-height: 46px;
`
const LogoText = styled.div`
  flex: 1;
  margin-left: 15px;
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
`

export default memo(Content)
