import React from 'react'
import styled from 'styled-components'

const TableTh = ({ title, onClick, sort, asc }) => (
  <ThWrapper onClick={onClick}>
    <ThWrapperTitle>{title}</ThWrapperTitle>
    {sort && <i className={asc ? 'fa fa-arrow-up' : 'fa fa-arrow-down'} />}
  </ThWrapper>
)

const ThWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  font-size: 9px;
  text-transform: uppercase;
`
const ThWrapperTitle = styled.div`
  margin-right: 10px;
  font-size: 11px;
  margin-top: 1px;
`

export default TableTh
