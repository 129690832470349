import api, { withConfig } from 'app/api'
import { stringify } from 'query-string'
import axios from 'axios'

export const list = data =>
  withConfig(config =>
    axios
      .get(api`/v1/reports/posts${'?' + stringify(data)}`, config)
      .then(({ data }) => data)
  )
export const get = (id, data) =>
  withConfig(config =>
    axios
      .get(api`/v1/reports/posts/${id + '?' + stringify(data)}`, config)
      .then(({ data }) => data)
  )
export const getPostsReports = data =>
  withConfig(config =>
    axios
      .get(api`/v1/posts/reports${'?' + stringify(data)}`, config)
      .then(({ data }) => data)
  )
export const validatePost = id =>
  withConfig(config =>
    axios
      .put(api`/v1/posts/${id}/validate`, null, config)
      .then(({ data }) => data)
  )
