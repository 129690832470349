/* eslint-disable */
import React, { memo, useState, useEffect } from 'react'
import ReactPlayer from 'react-player'
import { get } from 'app/api/posts'
import Content from 'app/components/Content'
import Spinner from 'app/components/Spinner'
import UserPhoto from 'app/components/UserPhoto'
import { format, parseISO } from 'date-fns'

const User = ({ match }) => {
  const [post, setPost] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    get(match.params.id).then(response => {
      setPost(response.data.post)
      setLoading(false)
    })
  }, [])

  if (loading) {
    return (
      <Content>
        <Spinner />
      </Content>
    )
  }

  return (
    <Content>
      <div className="row">
        <div className="pull-left col-md-8">
          <h4 className="font-weight-bold py-3 mb-1">
            <span className="text-muted font-weight-light">Detalhes / </span> Publicação de {post.owner.name}
          </h4>
        </div>
      </div>

      <div className="row">
        <div className="col-md-5">
          <div className="card">
            <h6 className="card-header">Publicação</h6>
            {Boolean(post.entries) &&
              post.entries.map(entry => {
                if (entry.type === 'picture') {
                  return (
                    <div className="img-thumbnail">
                      <img
                        src={entry.url}
                        alt={entry.name}
                        className="img-fluid"
                        style={{ minWidth: '100%' }}
                      />
                    </div>
                  )
                } else {
                  return (
                    <ReactPlayer
                      url={entry.url}
                      width={'100%'}
                      controls={true}
                    />
                  )
                }
              })}
            {Boolean(post.description) && (
              <div className="card-body">
                <div>
                  <p style={{ whiteSpace: 'pre-wrap', marginBottom: 0 }}>
                    <div
                      dangerouslySetInnerHTML={{ __html: post.description }}
                    />
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="col-md-7">
          <div className="card">
            <h6 className="card-header">Informações</h6>
            <table className="table card-table">
              <tbody>
                <tr>
                  <td>Autor</td>
                  <td>
                    <div className="media align-items-center">
                      <UserPhoto user={post.owner} />
                      <div className="media-body ml-3">
                        <div className="d-block text-body">
                          <strong>{post.owner.name}</strong>
                          <br />
                          <span className="text-muted">{post.owner.email}</span>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Curtidas</td>
                  <td>{Number(post.likes)}</td>
                </tr>
                <tr>
                  <td>Comentários</td>
                  <td>{Number(post.comments)}</td>
                </tr>
                <tr>
                  <td>Denúncias</td>
                  <td>{Number(post.reports)}</td>
                </tr>
                <tr>
                  <td>Favoritos</td>
                  <td>{Number(post.bookmarks)}</td>
                </tr>
                <tr>
                  <td>Visualizações</td>
                  <td>{Number(post.views)}</td>
                </tr>
                {post.tags && Boolean(post.tags.length) && (
                  <tr>
                    <td>Tags</td>
                    <td>
                      {post.tags.map(tag => (
                        <div
                          key={tag}
                          class="badge badge-pill badge-success mr-1"
                        >
                          {tag}
                        </div>
                      ))}
                    </td>
                  </tr>
                )}
                {post.hashtags && Boolean(post.hashtags.length) && (
                  <tr>
                    <td>Hashtags</td>
                    <td>
                      {post.hashtags.map(hashtag => (
                        <div
                          key={hashtag}
                          class="badge badge-pill badge-success mr-1"
                        >
                          #{hashtag}
                        </div>
                      ))}
                    </td>
                  </tr>
                )}
                <tr>
                  <td>Publicação</td>
                  <td>
                    {format(parseISO(post.created_at), 'dd/MM/yyyy HH:mm')}
                  </td>
                </tr>
                <tr>
                  <td>Atualização</td>
                  <td>
                    {format(parseISO(post.updated_at), 'dd/MM/yyyy HH:mm')}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {post.attachments && Boolean(post.attachments.length) && (
            <div className="card mt-4">
              <h6 className="card-header">Anexos</h6>
              <table className="table card-table">
                <tbody>
                  {post.attachments.map(attachment => (
                    <tr>
                      <td>
                        <b>{attachment.name}</b>
                        <br />
                        <small>{attachment.downloads} downloads</small>
                      </td>
                      <td align={'right'}>
                        <a
                          href={attachment.url}
                          className="btn btn-md btn-primary"
                          target={'_blank'}
                        >
                          <span class="fa fa-cloud-download-alt"></span> Baixar
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </Content>
  )
}

export default memo(User)
