export default [
  { initials: 'AC', coords: [-8.77, -70.55] },
  { initials: 'AL', coords: [-9.71, -35.73] },
  { initials: 'AM', coords: [-3.07, -61.66] },
  { initials: 'AP', coords: [1.41, -51.77] },
  { initials: 'BA', coords: [-12.96, -38.51] },
  { initials: 'CE', coords: [-3.71, -38.54] },
  { initials: 'DF', coords: [-15.83, -47.86] },
  { initials: 'ES', coords: [-19.19, -40.34] },
  { initials: 'GO', coords: [-16.64, -49.31] },
  { initials: 'MA', coords: [-2.55, -44.3] },
  { initials: 'MT', coords: [-12.64, -55.42] },
  { initials: 'MS', coords: [-20.51, -54.54] },
  { initials: 'MG', coords: [-18.1, -44.38] },
  { initials: 'PA', coords: [-5.53, -52.29] },
  { initials: 'PB', coords: [-7.06, -35.55] },
  { initials: 'PR', coords: [-24.89, -51.55] },
  { initials: 'PE', coords: [-8.28, -35.07] },
  { initials: 'PI', coords: [-8.28, -43.68] },
  { initials: 'RJ', coords: [-22.84, -43.15] },
  { initials: 'RN', coords: [-5.22, -36.52] },
  { initials: 'RO', coords: [-11.22, -62.8] },
  { initials: 'RS', coords: [-30.01, -51.22] },
  { initials: 'RR', coords: [1.89, -61.22] },
  { initials: 'SC', coords: [-27.33, -49.44] },
  { initials: 'SE', coords: [-10.9, -37.07] },
  { initials: 'SP', coords: [-23.55, -46.64] },
  { initials: 'TO', coords: [-10.25, -48.25] }
]
