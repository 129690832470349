import React, { memo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { signInUser } from 'app/modules/app'
import { useDispatch } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Spinner from 'app/components/Spinner'
import { AuthWrapper, Img } from './index.styled'

const validationSchema = Yup.object().shape({
  email: Yup.string().required(),
  password: Yup.string().required()
})

const Login = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const handleAuth = async values => {
    try {
      setLoading(true)
      await dispatch(signInUser(values))
      history.push('/')
    } catch ({ response }) {
      setLoading(false)
    }
  }

  return (
    <>
      <AuthWrapper className="authentication-wrapper authentication-2 ui-bg-cover ui-bg-overlay-container px-4">
        <div className="ui-bg-overlay bg-dark opacity-25"></div>

        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={validationSchema}
          onSubmit={handleAuth}
        >
          {props => (
            <div className="authentication-inner py-5">
              <div className="card">
                <div className="p-4 p-sm-5">
                  <div className="d-flex justify-content-center align-items-center pb-2 mb-4">
                    <div className="ui-w-120">
                      <div className="w-120 position-relative">
                        <Img
                          src={require('app/assets/img/logo.png')}
                          alt="Praticantes"
                        />
                      </div>
                    </div>
                  </div>

                  <h5 className="text-center text-muted font-weight-normal mb-4">
                    Entre com seus dados
                  </h5>
                  <form onSubmit={props.handleSubmit}>
                    <div className="form-group">
                      <label className="form-label">E-mail</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="E-mail"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.email}
                        name="email"
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label d-flex justify-content-between align-items-end">
                        <div>Senha</div>
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Senha"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.password}
                        name="password"
                      />
                    </div>
                    <div className="d-flex justify-content-between align-items-center m-0">
                      <label className="custom-control custom-checkbox m-0">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                        />
                      </label>
                      <input
                        type="submit"
                        value="Entrar"
                        className="btn btn-primary"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </AuthWrapper>

      {loading && <Spinner />}
    </>
  )
}

export default memo(Login)
