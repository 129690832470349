import React, { memo, useEffect } from 'react'
import styled from 'styled-components'
import Chart from 'chart.js'

const LineChart = props => {
  const { id, labels, datasets } = props

  useEffect(() => {
    new Chart(id, {
      type: 'line',
      data: { labels, datasets },
      options: {
        responsive: true,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      }
    })
  }, [id, labels, datasets])

  return (
    <Wrapper>
      <canvas id={id} width={400} height={150}></canvas>
    </Wrapper>
  )
}

const Wrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 150px;
`

export default memo(LineChart)
