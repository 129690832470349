import React, { memo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setListSort } from 'app/modules/hashtags'
import { get } from 'app/api/hashtags'
import Content from 'app/components/Content'
import Spinner from 'app/components/Spinner'
import TableTh from 'app/components/Table/TableTh'
import Pagination from 'app/components/Pagination'
import { format, parseISO } from 'date-fns'

const Hashtags = () => {
  const dispatch = useDispatch()
  const { sort, asc } = useSelector(state => state.hashtags)
  const [hashtags, setHashtags] = useState([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [skip, setSkip] = useState(0)
  const limit = 50

  useEffect(() => {
    setLoading(true)
    get({ skip, limit, sort, asc }).then(response => {
      setTotal(response.data.total)
      setHashtags(response.data.hashtags)
      setLoading(false)
    })
  }, [skip, limit, sort, asc])

  const handleSort = sort => {
    dispatch(setListSort({ sort, asc: !asc }))
  }

  return (
    <Content>
      <h4 className="font-weight-bold py-3 mb-1">
        <span className="text-muted font-weight-light">Lista /</span> Hashtags
      </h4>

      {loading && <Spinner />}
      <div className="card">
        <table className="table card-table">
          <thead className="thead-light">
            <tr>
              <th>
                <TableTh
                  title={'Nome'}
                  sort={sort === 'name'}
                  asc={asc}
                  onClick={() => handleSort('name')}
                />
              </th>
              <th>
                <TableTh
                  title={'Publicações'}
                  sort={sort === 'posts'}
                  asc={asc}
                  onClick={() => handleSort('posts')}
                />
              </th>
              <th>
                <TableTh
                  title={'Primeira utilização'}
                  sort={sort === 'created_at'}
                  asc={asc}
                  onClick={() => handleSort('created_at')}
                />
              </th>
              <th>
                <TableTh
                  title={'Última utilização'}
                  sort={sort === 'updated_at'}
                  asc={asc}
                  onClick={() => handleSort('updated_at')}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {hashtags.map(hashtag => {
              return (
                <tr key={hashtag.id}>
                  <td>{hashtag.name}</td>
                  <td>{hashtag.posts}</td>
                  <td>
                    {format(parseISO(hashtag.created_at), 'dd/MM/yyyy HH:mm')}
                  </td>
                  <td>
                    {format(parseISO(hashtag.updated_at), 'dd/MM/yyyy HH:mm')}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <Pagination
        total={total}
        skip={skip}
        limit={limit}
        onClick={({ skip }) => setSkip(skip)}
      />
    </Content>
  )
}

export default memo(Hashtags)
