import api, { withConfig } from 'app/api'
import { stringify } from 'query-string'
import axios from 'axios'

export const get = data =>
  withConfig(config =>
    axios
      .get(api`/v1/reports/activities${'?' + stringify(data)}`, config)
      .then(({ data }) => data)
  )
