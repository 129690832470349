import React from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Login from 'app/containers/Login'

const AuthProvider = ({ component: Component, ...props }) => {
  const location = useLocation()
  const { access_token } = useSelector(state => state.app)

  if (!access_token) {
    if (location.pathname !== '/recovery') {
      return <Login />
    }
  }

  return <Component {...props} />
}

export default AuthProvider
