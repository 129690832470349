import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Users from 'app/containers/Users'
import User from 'app/containers/Users/User'
import Tags from 'app/containers/Tags'
import Hashtags from 'app/containers/Hashtags'
import Advertisement from 'app/containers/Advertisement'
import Post from 'app/containers/Post'
import Posts from 'app/containers/Posts'
import Comments from 'app/containers/Comments'
import Reports from 'app/containers/Reports'
import Dashboard from 'app/containers/Dashboard'
import Analytics from 'app/containers/Analytics'
import Settings from 'app/containers/Settings'

const ApplicationRoutes = () => {
  return (
    <section>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
        <Route path="/dashboard" exact component={Dashboard} />
        <Route path="/analytics" exact component={Analytics} />
        <Route path="/users" exact component={Users} />
        <Route path="/users/:id" exact component={User} />
        <Route path="/reports" exact component={Reports} />
        <Route path="/posts" exact component={Posts} />
        <Route path="/posts/:id" exact component={Post} />
        <Route path="/comments" exact component={Comments} />
        <Route path="/advertisements/:id" exact component={Advertisement} />
        <Route path="/tags" exact component={Tags} />
        <Route path="/hashtags" exact component={Hashtags} />
        <Route path="/settings" exact component={Settings} />
      </Switch>
    </section>
  )
}

export default ApplicationRoutes
