import React, { memo, useEffect, useState } from 'react'
import LineChart from 'app/components/Charts/LineChart'
import Spinner from 'app/components/Spinner'
import { getFollowsIndicators } from 'app/api/indicators'

const options = [
  {
    border: '#ff677d',
    background: 'rgba(255, 103, 125, 0.8)',
    label: 'Semana atual',
    variable: 'follows_current'
  },
  {
    border: '#ffae8f',
    background: 'rgba(255, 174, 143, 0.8)',
    label: 'Cinco semanas atrás',
    variable: 'follows_five_weeks_ago'
  }
]

const IndicatorsFollows = ({ filter }) => {
  const [labels, setLabels] = useState([])
  const [datasets, setDatasets] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (Boolean(filter.startDate) && Boolean(filter.endDate)) {
      setLoading(true)
      getFollowsIndicators(filter).then(response => {
        if (!response.data || !response.data.length) {
          return false
        }

        setLabels(
          response.data.map(dataset => {
            return 'Semana ' + dataset.week
          })
        )

        setDatasets(
          options.map(option => ({
            label: option.label,
            borderWidth: 1,
            borderColor: option.border,
            backgroundColor: option.background,
            data: response.data.map(data => data[option.variable])
          }))
        )

        setLoading(false)
      })
    }
  }, [filter])

  if (loading) {
    return <Spinner />
  }

  return (
    <LineChart
      id={'chart-bar-follows'}
      label={'Semana'}
      labels={labels}
      datasets={datasets}
    />
  )
}

export default memo(IndicatorsFollows)
