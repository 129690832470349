/* eslint-disable */
import React, { memo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setListFilter } from 'app/modules/users'
import { get } from 'app/api/users'
import DatePicker from 'react-datepicker'
import { format, parseISO } from 'date-fns'
import UserComments from 'app/components/UserComments'
import Content from 'app/components/Content'
import Spinner from 'app/components/Spinner'
import TableTh from 'app/components/Table/TableTh'

const User = ({ match }) => {
  const dispatch = useDispatch()
  const [user, setUser] = useState({})
  const [activities, setActivities] = useState({})
  const [loading, setLoading] = useState(false)
  const { startDate, endDate } = useSelector(state => state.users)

  useEffect(() => {
    setLoading(true)

    const filter = {}
    filter.startDate = format(parseISO(startDate), 'yyyy-MM-dd')
    filter.endDate = format(parseISO(endDate), 'yyyy-MM-dd')

    get(match.params.id, filter).then(response => {
      setUser(response.data.user)
      setActivities(response.data.activities)
      setLoading(false)
    })
  }, [startDate, endDate])

  const setStartDate = date => {
    dispatch(setListFilter({ startDate: date.toISOString(), endDate }))
  }

  const setEndDate = date => {
    dispatch(setListFilter({ startDate, endDate: date.toISOString() }))
  }

  if (loading) {
    return (
      <Content>
        <Spinner />
      </Content>
    )
  }

  return (
    <Content>
      <div className="row">
        <div className="pull-left col-md-8">
          <h4 className="font-weight-bold py-3 mb-1">
            <span className="text-muted font-weight-light">Lista / Usuários / </span> {user.name}
          </h4>
        </div>
        <div className="pull-right col-md-4">
          <div className="form-row">
            <div className="form-group col-md-6 mb-0">
              <DatePicker
                className={'form-control text-center'}
                selected={parseISO(startDate)}
                dateFormat={'dd/MM/yyyy'}
                popperPlacement={'top-start'}
                onChange={date => setStartDate(date)}
                startDate={parseISO(startDate)}
                endDate={parseISO(endDate)}
                selectsStart
              />
            </div>
            <div className="form-group col-md-6 mb-0">
              <DatePicker
                className={'form-control text-center'}
                selected={parseISO(endDate)}
                dateFormat={'dd/MM/yyyy'}
                popperPlacement={'top-end'}
                onChange={date => setEndDate(date)}
                startDate={parseISO(startDate)}
                endDate={parseISO(endDate)}
                minDate={parseISO(startDate)}
                selectsEnd
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <table className="table card-table">
          <thead className="thead-light">
            <tr>
              <th>
                <TableTh title={'Informação'} />
              </th>
              <th width={200}>
                <TableTh title={'Filtro'} />
              </th>
              <th width={200}>
                <TableTh title={'Total'} />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{`Novas publicações`}</td>
              <td>{activities.range ? Number(activities.range.posts) : '-'}</td>
              <td>{activities.all ? Number(activities.all.posts) : '-'}</td>
            </tr>
            <tr>
              <td>{`Novas publicações com mídia`}</td>
              <td>
                {activities.range
                  ? Number(activities.range.postsWithMedia)
                  : '-'}
              </td>
              <td>
                {activities.all ? Number(activities.all.postsWithMedia) : '-'}
              </td>
            </tr>
            <tr>
              <td>{`Novas publicações com anexo`}</td>
              <td>
                {activities.range
                  ? Number(activities.range.postsWithAttachments)
                  : '-'}
              </td>
              <td>
                {activities.all
                  ? Number(activities.all.postsWithAttachments)
                  : '-'}
              </td>
            </tr>
            <tr>
              <td>{`Curtidas em publicações`}</td>
              <td>{activities.range ? Number(activities.range.likes) : '-'}</td>
              <td>{activities.all ? Number(activities.all.likes) : '-'}</td>
            </tr>
            <tr>
              <td>{`Comentários em publicações`}</td>
              <td>
                {activities.range ? Number(activities.range.comments) : '-'}
              </td>
              <td>{activities.all ? Number(activities.all.comments) : '-'}</td>
            </tr>
            <tr>
              <td>{`Visualizações em publicações`}</td>
              <td>{activities.range ? Number(activities.range.views) : '-'}</td>
              <td>{activities.all ? Number(activities.all.views) : '-'}</td>
            </tr>
            <tr>
              <td>{`Downloads de arquivos`}</td>
              <td>
                {activities.range ? Number(activities.range.downloads) : '-'}
              </td>
              <td>{activities.all ? Number(activities.all.downloads) : '-'}</td>
            </tr>
            <tr>
              <td>{`Publicações salvas`}</td>
              <td>
                {activities.range ? Number(activities.range.bookmarks) : '-'}
              </td>
              <td>{activities.all ? Number(activities.all.bookmarks) : '-'}</td>
            </tr>
            <tr>
              <td>{`Novos seguidores`}</td>
              <td>
                {activities.range ? Number(activities.range.followers) : '-'}
              </td>
              <td>{activities.all ? Number(activities.all.followers) : '-'}</td>
            </tr>
            <tr>
              <td>{`Novos seguindo`}</td>
              <td>
                {activities.range ? Number(activities.range.following) : '-'}
              </td>
              <td>{activities.all ? Number(activities.all.following) : '-'}</td>
            </tr>
            <tr>
              <td>{`Novos inscritos`}</td>
              <td>
                {activities.range ? Number(activities.range.subscribers) : '-'}
              </td>
              <td>
                {activities.all ? Number(activities.all.subscribers) : '-'}
              </td>
            </tr>
            <tr>
              <td>{`Novas inscrições`}</td>
              <td>
                {activities.range
                  ? Number(activities.range.subscriptions)
                  : '-'}
              </td>
              <td>
                {activities.all ? Number(activities.all.subscriptions) : '-'}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="row">
        <div className="pull-left col-md-8">
          <h4 className="font-weight-bold py-3 mb-1 mt-3">
            <span className="text-muted font-weight-light">Lista / Usuários / </span> Comentários
          </h4>
        </div>
      </div>

      <UserComments user={user}/>
      
    </Content>
  )
}

export default memo(User)
