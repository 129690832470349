import api, { withConfig } from 'app/api'
import { stringify } from 'query-string'
import axios from 'axios'

export const getSettings = data =>
  withConfig(config =>
    axios
      .get(api`/v1/settings${'?' + stringify(data)}`, config)
      .then(({ data }) => data)
  )
export const updateSetting = data =>
  withConfig(config =>
    axios.put(api`/v1/settings`, data, config).then(({ data }) => data)
  )
