import React, { memo } from 'react'
import Content from 'app/components/Content'
import PostsReports from 'app/components/Reports/PostsReports'
import AdvertisementsReports from 'app/components/Reports/AdvertisementsReports'

const Reports = () => (
  <Content>
    <h4 className="font-weight-bold py-3 mb-1">
      <span className="text-muted font-weight-light">Denúncias /</span>{' '}
      Publicações
    </h4>

    <PostsReports />

    <h4 className="font-weight-bold py-3 mb-1 mt-4">
      <span className="text-muted font-weight-light">Denúncias /</span>{' '}
      Classificados
    </h4>

    <AdvertisementsReports />
  </Content>
)

export default memo(Reports)
