import React from 'react'
import ReactDOM from 'react-dom'
import { Route, Switch } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { RouterProvider } from 'app/lib/RouterProvider'
import { PersistGate } from 'redux-persist/lib/integration/react'
import AuthProvider from 'app/lib/AuthProvider'
import Login from 'app/containers/Login'
import ApplicationRoutes from 'app/routes'
import { store, persistor } from 'app/store'
import ptBR from 'date-fns/locale/pt-BR'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import 'app/assets/styles'

registerLocale('ptBR', ptBR)
setDefaultLocale('ptBR')

const Root = ({ store }) => {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
        <RouterProvider>
          <Switch>
            <Route exact path="/login" component={Login} />
            <AuthProvider path="/" component={ApplicationRoutes} />
          </Switch>
        </RouterProvider>
      </PersistGate>
    </ReduxProvider>
  )
}

ReactDOM.render(<Root store={store} />, document.getElementById('root'))
document.body.classList.add('default-style')
document.body.classList.add('layout-fixed-offcanvas')
document.body.classList.add('layout-navbar-fixed')
document.body.classList.add('layout-collapsed')
