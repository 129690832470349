import React, { memo, useEffect, useState } from 'react'
import LineChart from 'app/components/Charts/LineChart'
import Spinner from 'app/components/Spinner'
import { getPostsIndicators } from 'app/api/indicators'

const options = [
  {
    border: '#ff677d',
    background: 'rgba(255, 103, 125, 0.8)',
    label: 'Apenas fotos',
    variable: 'posts_only_pictures'
  },
  {
    border: '#cd6684',
    background: 'rgba(205, 102, 132, 0.8)',
    label: 'Com fotos',
    variable: 'posts_with_pictures'
  },
  {
    border: '#ffae8f',
    background: 'rgba(255, 175, 143, 0.8)',
    label: 'Apenas videos',
    variable: 'posts_only_videos'
  },
  {
    border: '#ff677d',
    background: 'rgba(255, 103, 125, 0.8)',
    label: 'Com videos',
    variable: 'posts_with_videos'
  },
  {
    border: '#cd6684',
    background: 'rgba(205, 102, 132, 0.8)',
    label: 'Apenas anexos',
    variable: 'posts_only_attachments'
  },
  {
    border: '#ffae8f',
    background: 'rgba(255, 174, 143, 0.8)',
    label: 'Com anexos',
    variable: 'posts_with_attachments'
  },
  {
    border: '#ff677d',
    background: 'rgba(255, 103, 125, 0.8)',
    label: 'Apenas descrição',
    variable: 'posts_only_description'
  },
  {
    border: '#cd6684',
    background: 'rgba(205, 102, 132, 0.8)',
    label: 'Com descrição',
    variable: 'posts_with_description'
  },
  {
    border: '#ffae8f',
    background: 'rgba(255, 175, 143, 0.8)',
    label: 'Total',
    variable: 'posts'
  }
]

const IndicatorsPosts = ({ filter }) => {
  const [labels, setLabels] = useState([])
  const [datasets, setDatasets] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (Boolean(filter.startDate) && Boolean(filter.endDate)) {
      setLoading(true)
      getPostsIndicators(filter).then(response => {
        if (!response.data || !response.data.length) {
          return false
        }

        setLabels(
          response.data.map(dataset => {
            return 'Semana ' + dataset.week
          })
        )

        setDatasets(
          options.map(option => ({
            label: option.label,
            borderWidth: 1,
            borderColor: option.border,
            backgroundColor: option.background,
            data: response.data.map(data => data[option.variable])
          }))
        )

        setLoading(false)
      })
    }
  }, [filter])

  if (loading) {
    return <Spinner />
  }

  return (
    <LineChart
      id={'chart-bar-posts'}
      label={'Semana'}
      labels={labels}
      datasets={datasets}
    />
  )
}

export default memo(IndicatorsPosts)
