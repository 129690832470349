import React, { memo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setListSort } from 'app/modules/users'
import { list } from 'app/api/users'
import { Link } from 'react-router-dom'
import Content from 'app/components/Content'
import Spinner from 'app/components/Spinner'
import TableTh from 'app/components/Table/TableTh'
import Pagination from 'app/components/Pagination'
import UserPhoto from 'app/components/UserPhoto'
import { format, parseISO } from 'date-fns'

const Users = () => {
  const dispatch = useDispatch()
  const { sort, asc } = useSelector(state => state.users)
  const [users, setUsers] = useState([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState(null)
  const [skip, setSkip] = useState(0)
  const limit = 50

  useEffect(() => {
    setLoading(true)
    list({ search, skip, limit, sort, asc }).then(response => {
      setTotal(response.data.total)
      setUsers(response.data.users)
      setLoading(false)
    })
  }, [search, skip, limit, sort, asc])

  const handleSort = sort => {
    dispatch(setListSort({ sort, asc: !asc }))
  }

  return (
    <Content>
      <div className="row">
        <div className="pull-left col-md-9">
          <h4 className="font-weight-bold py-3 mb-1">
            <span className="text-muted font-weight-light">Lista /</span>{' '}
            Usuários
          </h4>
        </div>
        <div className="pull-right col-md-3">
          <div className="form-row">
            <div className="form-group col-md-12 mb-0">
              <input
                onChange={e => setSearch(e.target.value)}
                type="text"
                className="form-control"
                placeholder="Buscar usuário por nome"
              />
            </div>
          </div>
        </div>
      </div>

      {loading && <Spinner />}
      <div className="card">
        <table className="table card-table">
          <thead className="thead-light">
            <tr>
              <th width={1}></th>
              <th>
                <TableTh
                  title={'Nome'}
                  sort={sort === 'name'}
                  asc={asc}
                  onClick={() => handleSort('name')}
                />
              </th>
              <th>
                <TableTh
                  title={'Publicações'}
                  sort={sort === 'posts'}
                  asc={asc}
                  onClick={() => handleSort('posts')}
                />
              </th>
              <th>
                <TableTh
                  title={'Seguidores'}
                  sort={sort === 'followers'}
                  asc={asc}
                  onClick={() => handleSort('followers')}
                />
              </th>
              <th>
                <TableTh
                  title={'Seguindo'}
                  sort={sort === 'following'}
                  asc={asc}
                  onClick={() => handleSort('following')}
                />
              </th>
              <th>
                <TableTh
                  title={'Inscritos'}
                  sort={sort === 'subscribers'}
                  asc={asc}
                  onClick={() => handleSort('subscribers')}
                />
              </th>
              <th>
                <TableTh
                  title={'Inscrições'}
                  sort={sort === 'subscriptions'}
                  asc={asc}
                  onClick={() => handleSort('subscriptions')}
                />
              </th>
              <th>
                <TableTh
                  title={'Curtidas'}
                  sort={sort === 'likes_made'}
                  asc={asc}
                  onClick={() => handleSort('likes_made')}
                />
              </th>
              <th>
                <TableTh
                  title={'Comentários'}
                  sort={sort === 'comments_made'}
                  asc={asc}
                  onClick={() => handleSort('comments_made')}
                />
              </th>
              <th>
                <TableTh
                  title={'Cadastro'}
                  sort={sort === 'created_at'}
                  asc={asc}
                  onClick={() => handleSort('created_at')}
                />
              </th>
              <th width={1}></th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => {
              return (
                <tr key={user.id}>
                  <td>
                    <UserPhoto user={user} />
                  </td>
                  <td>
                    {user.name}
                    <br />
                    {Boolean(user.city) && (
                      <small className="text-muted">{`${user.city}, ${user.state}`}</small>
                    )}
                  </td>
                  <td>{user.posts}</td>
                  <td>{user.followers}</td>
                  <td>{user.following}</td>
                  <td>{user.subscribers}</td>
                  <td>{user.subscriptions}</td>
                  <td>{user.likes_made}</td>
                  <td>{user.comments_made}</td>
                  <td>
                    {format(parseISO(user.created_at), 'dd/MM/yyyy HH:mm')}
                  </td>
                  <td>
                    <Link to={`/users/${user.id}`}>
                      <button className="btn icon-btn btn-sm btn-outline-primary">
                        <span className="far fa-eye"></span>
                      </button>
                    </Link>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <Pagination
        total={total}
        skip={skip}
        limit={limit}
        onClick={({ skip }) => setSkip(skip)}
      />
    </Content>
  )
}

export default memo(Users)
