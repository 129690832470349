import React, { memo, useState } from 'react'
import styled from 'styled-components'
import Content from 'app/components/Content'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

const Analytics = () => {
  const [tabindex, setTabindex] = useState(0)

  return (
    <Content>
      <div className="row">
        <div className="pull-left col-md-8">
          <h4 className="font-weight-bold py-4">
            <span className="text-muted font-weight-light">Dados /</span>{' '}
            Analytics
          </h4>
        </div>
      </div>

      <Tabs defaultIndex={tabindex} onSelect={index => setTabindex(index)}>
        <TabsMenu>
          <TabsMenuOption active={tabindex === 0}>
            Primeiras jornadas
          </TabsMenuOption>
          <TabsMenuOption active={tabindex === 1}>
            Primeiras jornadas feed
          </TabsMenuOption>
          <TabsMenuOption active={tabindex === 2}>
            Gráfico cohort
          </TabsMenuOption>
          <TabsMenuOption active={tabindex === 3}>
            Acessos de dispositivos
          </TabsMenuOption>
          <TabsMenuOption active={tabindex === 4}>
            Eventos mais frequentes
          </TabsMenuOption>
        </TabsMenu>

        <TabPanel>
          <iframe
            width={'100%'}
            height={620}
            frameborder={0}
            title={'Primeiras jornadas'}
            src={
              'https://firebasestorage.googleapis.com/v0/b/praticantes-27eeb.appspot.com/o/data%2Fprimeiras_jornadas.html?alt=media'
            }
          />
        </TabPanel>

        <TabPanel>
          <iframe
            width={'100%'}
            height={620}
            frameborder={0}
            title={'Primeiras jornadas no feed'}
            src={
              'https://firebasestorage.googleapis.com/v0/b/praticantes-27eeb.appspot.com/o/data%2Fprimeiras_jornadas_feed.html?alt=media'
            }
          />
        </TabPanel>

        <TabPanel>
          <iframe
            width={'100%'}
            height={620}
            frameborder={0}
            title={'Gráfico cohort'}
            src={
              'https://firebasestorage.googleapis.com/v0/b/praticantes-27eeb.appspot.com/o/data%2Ffigura_cohort.html?alt=media'
            }
          />
        </TabPanel>

        <TabPanel>
          <iframe
            width={'100%'}
            height={620}
            frameborder={0}
            title={'Acessos de dispositivos'}
            src={
              'https://firebasestorage.googleapis.com/v0/b/praticantes-27eeb.appspot.com/o/data%2Facessos_devices.html?alt=media'
            }
          />
        </TabPanel>

        <TabPanel>
          <iframe
            width={'100%'}
            height={620}
            frameborder={0}
            title={'Eventos mais frequentes'}
            src={
              'https://firebasestorage.googleapis.com/v0/b/praticantes-27eeb.appspot.com/o/data%2F200_eventos_mais_frequentes.html?alt=media'
            }
          />
        </TabPanel>
      </Tabs>
    </Content>
  )
}

const TabsMenu = styled(TabList)`
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  padding: 10px;
  margin-bottom: 20px;
`
const TabsMenuOption = styled(Tab)`
  text-align: center;
  padding: 5px 15px;
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  border-bottom-style: solid;
  border-color: #4e5155;
  border-bottom-width: ${props => (props.active ? '1px' : '0')};
  cursor: pointer;
`

export default memo(Analytics)
