import React, { memo } from 'react'
import styled from 'styled-components'

const UserPhoto = ({ user }) => {
  if (user && user.profile_picture) {
    return (
      <Photo>
        <img src={user.profile_picture} className="ui-w-40" alt={user.name} />
      </Photo>
    )
  } else {
    return (
      <Photo>
        <PhotoIcon className="fa fa-user" />
      </Photo>
    )
  }
}

const Photo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid #e8e8e8;
  border-radius: 40px;
  background: #f6f6f6;
  overflow: hidden;
`

const PhotoIcon = styled.div`
  color: #cbcbcb;
  font-size: 14px;
  margin-left: 1px;
`

export default memo(UserPhoto)
