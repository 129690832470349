import axios from 'axios'

axios.defaults.withCredentials = true

const config = {
  env: 'production',
  api: {
    dev: '192.168.1.4'
  }
}

const servers = {
  dev: `http://${config.api.dev}:5000/praticantes-27eeb/us-central1/app`,
  production: `https://api.praticantes.org`
}

var api = (path = [], ...params) => {
  const url =
    servers[config.env] +
    path
      .map((item, index) => [item].concat(params[index]))
      .flat()
      .filter(Boolean)
      .join('')

  return url
}

const getConfig = async () =>
  new Promise(async (resolve, reject) => {
    try {
      const store = require('app/store').store
      const { user } = store.getState().app
      const { data } = await axios
        .post(api`/v1/auth`, user)
        .then(({ data }) => data)

      resolve({
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${data.token}`
        }
      })
    } catch (err) {
      console.log(err)
      reject(err)
    }
  })

const withConfig = async request => {
  try {
    const config = await getConfig()

    return request(config)
  } catch (err) {
    console.log(err)
    throw new Error()
  }
}

export { withConfig }
export default api
